import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from "../../../components/News"
import ProductTopSection from "../../../components/ProductTopSelection"
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from "../../../components/Wethoughtyoumighthavequestions"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"

import headerImage from "../../../images/ViztosPos/top_header_art_double_tab.png"
import img1 from "../../../images/ViztosPos/1.png"
import img2 from "../../../images/ViztosPos/2.png"
import img3 from "../../../images/ViztosPos/3.png"
import img4 from "../../../images/ViztosPos/4.png"
import img5 from "../../../images/ViztosPos/5.png"
import img6 from "../../../images/ViztosPos/1.png"
import img7 from "../../../images/ViztosPos/7.png"
import img8 from "../../../images/ViztosPos/8.png"
import img9 from "../../../images/ViztosPos/9.png"
import img10 from "../../../images/ViztosPos/10.png"

import ImageWithTextSection from "../../../components/ImageWithTextSection"
import IconsAdded from "../../../components/IconsAdded"

const Viztos = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Visiomate - Viztos a Cafe / Oil Lubricant Management"}
      descrip = {"Our cafe/oil lubricant management software offers powerful tools for inventory control, sales tracking, customer relationship management, and more. Boost your business today!"}>
        <ProductTopSection
          headerData={{
            title:
              "Viztos: a POS solution perfect for Cafe & Lubricant Stations.  ",
            peragraph:
              "A customized Odoo-based POS solution designed to overcome common limitations faced by businesses. Perfect for drive-through cafes and lubricant stations, Viztos features a user-friendly Front End and an efficient Odoo backend, mitigating issues like multiple sessions and advanced UI design.",
            image: headerImage,
          }}
          data={data}
        />
        <div className="position-relative">
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Viztos?",
            headings: [
              {
                heading: "Ideal for Drive-through Cafes and Lubricant Stations",
                description:
                  "Viztos interface is finely tuned for drive-through cafes and lubricant stations, ensuring swift and effortless order creation for enhanced efficiency.",
              },
              {
                heading: "Job Card for Lubricant station",
                description:
                  "Viztos offers comprehensive solutions for lubricant stations, including features like job cards and vehicle mileage history tracking, seamlessly completing the order-taking process.",
              },
              {
                heading: "Better Collaboration",
                description:
                  "Viztos provides multiple interfaces for effective information dissemination to all stakeholders. These include a Customer Remote Order Screen, Kitchen Preparation Screen, Kitchen Live Order Screen, and Video & Audio Interface for drive-through customers, ensuring seamless communication and operation",
              },
              {
                heading: "Daily Sales & Closing Reports",
                description:
                  "Viztos offers a variety of reports tailored to assist both shop staff and the back-office team. These include Daily Sales, Hourly Sales, Petty Cash, Closing Reports, and many more, facilitating comprehensive oversight and analysis.",
              },
              {
                heading: "Holistic Data Integration",
                description:
                  "Integration of data across departments in Visiopack provides a comprehensive view of the organization, allowing for better-informed decision-making.",
              },
              {
                heading: "Time and Cost Savings",
                description:
                  "Automation of tasks, such as inventory management and sales reporting, saves time and reduces the need for manual labor.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <IconsAdded iconColor={"yellow"}/>
        </div>
        <ImageWithTextSection
          caseStudyData={{
            data: [
              {
                heading: "Customizable & Friendly POS Interface",
                description:
                  "The Customizable & Friendly interface streamlines transactions, allowing users to easily navigate through different options, select items, and process payments with a simple tap or swipe.",
                image: img1,
              },
              {
                heading: "TOPUP & Petty Cash",
                description:
                  "Shopkeepers have the flexibility to view and manage customer top-ups and daily petty cash directly within the POS interface.",
                image: img2,
              },
              {
                heading: "Orders History",
                description:
                  "A detailed order history allows shop keeper to view past transactions, monitor the status, and print duplicate invoices of their orders.",
                image: img3,
              },
              {
                heading: "Multiple Payment Methods",
                description:
                  "Viztos offers effortless payment processing with multiple methods and standout tap-to-pay integration, ensuring seamless transactions for customers.",
                image: img4,
              },
              {
                heading: "Dynamic Kitchen Display Screen",
                description:
                  "This technology leverages dynamic, real-time information to enhance communication and coordination between the kitchen staff, improving overall kitchen operations.",
                image: img5,
              },
              {
                heading: "Job Card",
                description:
                  "A lubricant job card is a document used in automotive maintenance to record details of lubrication services performed on a vehicle.",
                image: img6,
              },
              {
                heading: "Hourly Sales Report",
                description:
                  "Viztos hourly sales report provides detailed information about the sales transactions and revenue generated every hour of the session",
                image: img7,
              },
              {
                heading: "Sales Closing Report",
                description:
                  "Viztos sales closing report is a summary document that provides information about the sales transactions and revenue generated within a single business day.",
                image: img8,
              },
              {
                heading: "Price & Quantity Update",
                description:
                  "Viztos provides price & quantity update feature on POS level, if shop manager have the access to this feature then price & quantity can be managed with out going to the back office.",
                image: img9,
              },
              {
                heading: "Remote Dispaly for Drive-through",
                description:
                  "Viztos modernizes drive-through ordering with integrated video and audio conferencing, enhancing customer experience.",
                image: img10,
              },
            ],
          }}
          dataFormImages={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Simplified Steps to get you Started ",
            Data: [
              {
                peragraph: "Define Requirements",
                list: [
                  "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                  "List down the essential features and functionalities required.",
                ],
              },
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "UAT & User Feedback",
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                ],
              },
              {
                peragraph: "User Training",
                list: [
                  " User training is a critical component of successfully implementing the software solution.",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                ],
              },
              {
                peragraph: "Post-Implementation Support",
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ],
              },
            ],
          }}
        />
        <Wethoughtyoumighthavequestions
          data={[
            {
              question:
                "What is a Viztos system? ",
              answer:
                "A POS system is a software and hardware solution used by businesses to process transactions, manage inventory, track sales, and streamline operations at the point of sale.",
            },
            {
              question:
                "How does a Viztos system benefit businesses?",
              answer:
                "A POS system helps businesses improve efficiency, accuracy, and customer service by automating transactions, managing inventory in real-time, analyzing sales data, and facilitating seamless payment processing.",
            },
            {
              question:
                "What types of businesses can use a Viztos system?",
              answer:
                "POS systems are used by a wide range of businesses including retail stores, restaurants, cafes, bars, food trucks, salons, spas, gyms, and other service-oriented establishments.",
            },
            {
              question:
                "Can a Viztos system handle different payment methods?",
              answer:
                "Yes, modern POS systems support various payment methods including cash, credit cards, debit cards, TAP TO PAY, and sometimes alternative payment options like gift cards or loyalty points.",
            },
            {
              question:
                "How does inventory management work in a Viztos system?",
              answer:
                "Inventory management features allow businesses to track stock levels, receive alerts for low inventory, automate reordering, monitor product performance, and generate reports to optimize inventory control.",
            },
            {
              question:
                "Can a Viztos system integrate with other business software?",
              answer:
                "Viztos is already integrated with a complete ERP solution which can be used to support other departments. The backoffice ERP includes modules like CRM, Sales, Purchase, Inventory, Accounting etc.",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default Viztos
export const query = graphql`
  query VitozImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(
      relativePath: { eq: "ViztosPos/top_header_art_double_tab.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "ViztosPos/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "ViztosPos/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "ViztosPos/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "ViztosPos/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "ViztosPos/5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "ViztosPos/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "ViztosPos/7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "ViztosPos/8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "ViztosPos/9.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img10: file(relativePath: { eq: "ViztosPos/10.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
